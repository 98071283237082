<template>
  <div class="font ajust-margin">
    <br />
    <b-navbar toggleable="sm" type="light" variant="white">
      <b-navbar-brand style="margin-left: -0.9%">
        <p class="page_init mb-0">PÁGINA INICIAL</p>
        <p class="title">Usuários</p>
      </b-navbar-brand>
      <!-- Right aligned nav items -->
      <b-navbar-nav class="ml-auto">
        <b-nav-form>
          <b-form-input
            size="sm"
            class="mr-sm-2 buscar border-none"
            placeholder="Buscar usuários"
          ></b-form-input>
        </b-nav-form>
      </b-navbar-nav>
    </b-navbar>

    <div class="loading">
      <table class="table table-sm mt-0">
        <thead>
          <tr
            style="
              background: #f9f9f9 0% 0% no-repeat padding-box;
              border: 1px solid #e1e1e1;
              border-radius: 4px;
              opacity: 1;
            "
          >
            <th scope="col">Nome</th>
            <th scope="col">Telefone</th>
            <th scope="col">Email</th>
            <th scope="col">Status</th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(user, i) in user" :key="i">
            <td style="text-transform: uppercase">{{ user.first_name }}</td>
            <td>campo em falta</td>
            <td>{{ user.email }}</td>
            <td>campo em falta</td>
            <td>
              <router-link to="/editarUsuario">
                <span
                  style="color: #384364; cursor: pointer; font-size: 20px"
                  class="material-icons"
                >
                  edit
                </span>
              </router-link>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<style scoped lang="sass">
@import "~/public/styles/sass/index.scss"
</style>

<script>
import UserService from "../../services/UserService/UserService";

export default {
  name: "UsersPage",

  data() {
    return {
      users: [],
      userService: null,
    };
  },
  mounted() {
    this.userService = new UserService();
    this.getAllUsers();
  },
  methods: {
    async getAllUsers() {
      this.users = await this.userService.allUsers();
    },

    updateUsers(users) {
      this.users = users;
    },
  },
};
</script>

